import Cookies from 'js-cookie';

export default function homeIntro() {
	if ( $( '#intro-video' ).length > 0 ) {
		if ( Cookies.get( 'intro_views' ) ) {
			$( '#intro-video' ).remove();
		} else {
			$( 'body' ).addClass( 'intro-video-playing' );
			$( '#header' ).addClass( 'delayed' );
			const video = $( '#intro-video>video' );
			video[0].onended = function () {
				$( 'body' ).removeClass( 'intro-video-playing' );
				$( '#intro-video' ).fadeOut();
				Cookies.set( 'intro_views', true );
			};
		}
	}
}