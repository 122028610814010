require( 'owl.carousel' );
export default function home_carousel() {
	$( '#home-carousel .owl-carousel' ).owlCarousel( {
		items: 1,
		loop: true,
		dots: false,
		nav: true,
		autoplay: true,
		autoplayTimeout: 4000,
		autoplayHoverPause: true,
		smartSpeed: 1000,
		navText: [
			'<i class="fa fa-caret-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="fa fa-caret-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		]
	} );
	$( '.vinyl-banner .owl-carousel' ).owlCarousel( {
		items: 1,
		loop: true,
		dots: false,
		nav: false,
		autoplay: true,
		autoplayTimeout: 4000,
		autoplayHoverPause: true,
		smartSpeed: 1000,
	} );
}