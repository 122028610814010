require( 'owl.carousel' );
export default function home_carousel() {
	$( '.search-releases .owl-carousel' ).owlCarousel( {
		items: 1,
		loop: $( '.search-releases .owl-carousel article' ).length > 4 ? true : false,
		dots: false,
		nav: true,
		center: true,
		autoplay: true,
		autoplayTimeout: 4000,
		autoplayHoverPause: true,
		smartSpeed: 1000,
		navText: [
			'<i class="fa fa-caret-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="fa fa-caret-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		],
		responsive: {
			0: {
				items: 1,
				margin: 0,
				loop: $( '.search-releases .owl-carousel article' ).length > 2 ? true : false
			},
			768: {
				items: 2,
				margin: 30
			},
			992: {
				items: 3,
				margin: 30
			},
			1200: {
				items: 4,
				margin: 30
			}
		}
	} );
}