import 'magnific-popup';
import Cookies from 'js-cookie';

export default function newsletter() {

	const newsletter_viewed = Cookies.get( 'milan_newsletter' );
	if ( !newsletter_viewed ) {
		$.magnificPopup.open( {
			items: {
				src: '#newsletter-modal',
				type: 'inline'
			}
		} );
		Cookies.set( 'milan_newsletter', true, { expires: 7 } );
	}

	$( '.newsletter-link' ).on( 'click', function ( e ) {
		e.preventDefault();
		$.magnificPopup.open( {
			items: {
				src: '#newsletter-modal',
				type: 'inline'
			}
		} );
	} );

	$('#records-newsletter-sign-up-v1-jan-2020').on('submit', function(e) {
		e.preventDefault();
		const DATA = $(this).serialize();
	
		$.ajax({
			type: 'POST',
			url: $(this).attr('action'),
			dataType: 'json',
			data: DATA,
			xhrFields: {
				withCredentials: false,
			},
			success: function (data) {
				console.log('submitted')
				$('#records-newsletter-sign-up-v1-jan-2020').html('<p class="newsletter-thanks">Thank you for signing up!</p>');
			},
			error: function (err) {
				console.log(err);
			}  
		});
	});
}