import nav from './modules/nav';
import home_intro from './modules/home-intro';
import home_carousel from './modules/home-carousel';
import home_featured from './modules/home-featured';
import releases_search from './modules/releases-search';
import newsletter from './modules/newsletter';
import single from './modules/single';
import search from './modules/search';
import AOS from 'aos';

$( document ).ready( function ( $ ) {
	nav();
	home_intro();
	home_carousel();
	home_featured();
	releases_search();
	newsletter();
	single();
	search();

	AOS.init( {
		duration: 600,
		easing: 'ease-in-out'
	} );

	$( '.btn-contact-trigger' ).magnificPopup( {
		type: 'inline',
		midClick: true
	} );
} );
