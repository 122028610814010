export default function single() {
	/*$( window ).on( 'scroll', function ( e ) {
			const img = $( '.art-col img' );
			const post_banner = $( '.post-banner' );
			let post_banner_offset = 0;
			let scroll_top = $( window ).scrollTop();

			if ( post_banner.length > 0 ) {
				post_banner_offset = post_banner.outerHeight( true );
			}

			const padding = $( 'body' ).innerHeight() - $( 'body' ).height();
			const margin = $( '.inner-content' ).outerHeight( true ) - $( '.inner-content>.container' ).height();
			let header_offset = padding + margin;
			const page_content_top = $( '.page-body' ).offset().top;
			const page_content_bottom = page_content_top + $( '.page-body' ).height();

			if ( verge.viewportW() >= 992 ) {
				if ( scroll_top > 0 && scroll_top > page_content_top - header_offset ) {
					if ( scroll_top < page_content_bottom - header_offset - img.outerHeight() ) {
						img.css( 'top', scroll_top - post_banner_offset );
					} else {
						const max = $( '.page-body' ).height() - img.outerHeight();
						img.css( 'top', max );
					}
				} else {
					img.css( 'top', 0 );
				}
			} else {
				img.css( 'top', 0 );
			}
		}
	);*/

	function resize_spotify() {
		$( '.wp-block-columns figure.is-provider-spotify  iframe' ).each( function ( e ) {
			const imgs = $( this ).parents( '.wp-block-columns' ).find( 'img' );
			if ( imgs.length > 0 ) {
				const img = $( imgs[0] );
				$( this ).css( 'height', img.height() );
			}
		} );
	}

	resize_spotify();

	$( window ).on( 'resize', resize_spotify );

	$( '.other-releases' ).owlCarousel( {
		items: 1,
		loop: $( '.search-releases .owl-carousel article' ).length > 4 ? true : false,
		dots: false,
		nav: true,
		autoplay: true,
		center: false,
		autoplayTimeout: 4000,
		autoplayHoverPause: true,
		smartSpeed: 1000,
		navText: [
			'<i class="fa fa-caret-left" aria-hidden="true"></i><span class="sr-only">Prev</span>',
			'<i class="fa fa-caret-right" aria-hidden="true"></i><span class="sr-only">Next</span>'
		],
		responsive: {
			0: {
				items: 1,
				margin: 0,
				loop: $( '.search-releases .owl-carousel article' ).length > 2 ? true : false
			},
			768: {
				items: 2,
				margin: 30
			},
			992: {
				items: 3,
				margin: 30
			},
			1200: {
				items: 4,
				margin: 30
			}
		}
	} );

}