export default function releases_search() {
	/*$( '.releases-search .search-clear' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( '.releases-search .search-form input' ).val( '' );
	} );*/

	$( '.release-sort>select' ).on( 'change', function ( e ) {
		e.preventDefault();
		const search_form = $( '.search-form' );
		const val = $( this ).val();

		search_form.append( '<input type="hidden" name="sort_by" value="' + val + '" />' );
		search_form.submit();
	} );

	$( '.release-genres-toggle' ).on( 'click', function ( e ) {
		e.preventDefault();
		const search_form = $( '.search-form' );
		$( '.radio-groups' ).toggleClass( 'open' );
		$( this ).find( 'i' ).toggleClass( 'fa-plus' ).toggleClass( 'fa-minus' );
	} );

	$( '.radio-groups input' ).on( 'change', function ( e ) {
		const search_form = $( '.search-form' );
		search_form.find( '.search-form-genre' ).remove();
		if ( $( this ).attr( 'id' ) === 'all_genres' ) {
			$( '.radio-groups input' ).prop( 'checked', false );
			//search_form.append( '<input class="search-form-genre" type="hidden" name="filter_genres[]" value="" />' );
		} else {
			$( '#all_genres' ).prop( 'checked', false );
			$( '.radio-groups input:checked' ).each( function ( index, item ) {
				const val = $( item ).val();
				search_form.append( '<input class="search-form-genre" type="hidden" name="filter_genres[]" value="' + val + '" />' );
			} );
		}
		search_form.submit();
	} );
}
